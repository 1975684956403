import createSagaMiddleware from 'redux-saga';
import { applyMiddleware, compose, createStore, combineReducers } from 'redux';

import sagas from './sagas';
import reducer from './reducers';

const sagaMiddleware = createSagaMiddleware();

export default (initial = {}) => {
  const middleware = [sagaMiddleware];

  let composeEnhancers = compose;
  if (process.env.NODE_ENV === 'development') {
    if (typeof window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ === 'function') {
      composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
    }
  }

  const store = createStore(
    combineReducers({ root: reducer }),
    initial,
    composeEnhancers(applyMiddleware(...middleware)),
  );

  sagas.forEach(sagaMiddleware.run);

  return store;
};
